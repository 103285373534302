<template>
  <div
    class="bg-white mb-1"
    style="
      border-radius: 5px;
      padding: 12px;
      box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);
    "
  >
    <h3 class="font-weight-bolder text-body">
      {{ $t(ORDER_INFORMATION_TITLE) }}
    </h3>
    <b-form>
      <b-row>
        <b-col
          md="12"
          class="d-flex align-items-center"
        >
          <select-account
            class="flex-grow-1"
            :customer-i-d="supplierId ? supplierId : customerID"
            :auto-suggest-table-columns="suggestionTableColumnsSupplier"
            :module-name-orders="MODULE_NAME_ORDERS"
            :module-name="MODULE_NAME"
            label="Supplier Name"
            :section-configs="{
              default:{
                onSelected: null,
              },
              name: 'default', type: 'auto-suggest-customer-section', limit: 3
            }"
            :is-search-button="true"
            @selectHandler="selectHandler"
            @onSearchEmpty="onSearchEmpty"
          /></b-col>
      </b-row>
      <b-row>
        <b-col class="6 mt-1">
          <component
            :is="customerInformation[field].type"
            v-for="field in ['required_by']"
            :key="field"
            v-model="customerInformationModel[field]"
            v-bind="getProps(field, customerInformation, true, 'required_by', getParentValue)"
          />
          <component
            :is="customerInformation[field].type"
            v-for="field in ['billing_contact']"
            :key="field"
            v-model="customerInformationModel[field]"
            v-bind="getProps(field, customerInformation, true, 'billing_contact', getParentValue)"
            @input="getBillingContact($event, field)"
          >
            <template slot="after-input">
              <button-dashed
                class="btnBorderRevert  ml-1"
                :disabled="!customerInformationModel.customer_id"
                :class="!customerInformationModel.customer_id ? 'opacity-50': null"
              >
                <feather-icon
                  icon="LAddButtonIcon"
                  class="cursor-pointer"
                  size="34"
                  @click="newBillingContact"
                />
              </button-dashed>
            </template>
          </component>
          <component
            :is="customerInformation[field].type"
            v-for="field in ['billing_phone', 'billing_email']"
            :key="field"
            :ref="`${field}_ref`"
            v-model="customerInformationModel[field]"
            class="h-auto"
            :is-editable="true"
            v-bind="getProps(field, customerInformation)"
          />
          <div :class="`${!hasAddress ? 'billing_address-border-danger' : ''}`">
            <component
              :is="customerInformation['billing_address'].type"
              ref="billing_address_ref"
              v-model="customerInformationModel['billing_address']"
              class="h-auto"
              :is-editable="true"
              v-bind="getProps('billing_address', customerInformation)"
            />
          </div>
          <component
            :is="customerInformation['payment_term_id'].type"
            ref="payment_term_id_ref"
            v-model="customerInformationModel['payment_term_id']"
            v-bind="getProps('payment_term_id', customerInformation)"
          />
        </b-col>
        <b-col class="6 mt-1">
          <attachment
            :module-name="MODULE_NAME"
          />
          <component
            :is="customerInformation['internal_account_notes'].type"
            ref="internal_account_notes_ref"
            v-model="customerInformationModel['internal_account_notes']"
            :is-editable="true"
            class="h-auto"
            v-bind="getProps('internal_account_notes', customerInformation)"
          />
          <component
            :is="customerInformation[field].type"
            v-for="field in ['internal_order_reference', 'external_order_notes']"
            :key="field"
            :ref="`${field}_ref`"
            v-model="customerInformationModel[field]"
            class="h-auto"
            v-bind="getProps(field, customerInformation)"
          />
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<script>
import {
  BButton, BCol, BRow, BForm, BFormInput,
} from 'bootstrap-vue'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import SelectAccount from '@/views/main/orders/components/use-as-global/SelectAccount.vue'
import { getProps, getValueFromGivenObjectByKey } from '@/@core/utils/utils'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'
import Attachment from '@/views/main/orders/components/order-attachment/Attachment.vue'
import LTable from '@/views/components/LTable/LTable.vue'
import config from '../config'

export default {
  name: 'OrderInformation',
  components: {
    LTable,
    ButtonDashed,
    BRow,
    BCol,
    BForm,
    BButton,
    ErrorAlert,
    BFormInput,
    Attachment,
    SelectAccount,
  },
  props: {
    supplierId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      hasAddress: true,
    }
  },
  computed: {
    customerInformationModel() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
    customerID() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm.customer_id
    },
  },
  created() {
    if (this.supplierId) {
      this.onCustomerLoadingIfPropsHasCustomerID = true
      this.$store.dispatch(`${this.MODULE_NAME}/getCustomer`, { id: this.supplierId })
        .then(({ data: { data } }) => {
          this.searchQuery = data.cust_name_dba
          this.selectHandler(data)
        })
        .finally(() => {
          this.onCustomerLoadingIfPropsHasCustomerID = false
        })
    }
  },
  methods: {
    newBillingContact() {
      const route = this.$router.resolve({ name: 'home-customers-contacts-create', params: { id: this.customerInformationModel.customer_id } })
      window.open(route.href)
    },
    getParentValue() {
      return this.customerInformationModel.customer_id
    },
    getBillingContact(obj, field) {
      if (field !== 'billing_contact' && !obj) return false
      if (obj) {
        this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
          ...this.customerInformationModel,
          billing_phone: getValueFromGivenObjectByKey(obj, 'phone_one'),
          billing_email: getValueFromGivenObjectByKey(obj, 'email'),
        })
      }
    },
    onSearchEmpty() {
      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
        attachments: [],
        isEmpty: true,
      })
      this.hasAddress = true
    },
    selectHandler(item) {
      this.hasAddress = true

      const {
        id,
        customer_detail,
        default_contact,
        default_address,
      } = item

      if (!default_address) {
        this.hasAddress = false
      }

      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
        ...this.customerInformationModel,
        customer_id: id,
        payment_term_id: getValueFromGivenObjectByKey(customer_detail, 'payment_term', null),
        billing_phone: getValueFromGivenObjectByKey(default_contact, 'phone_one'),
        billing_email: getValueFromGivenObjectByKey(default_contact, 'email'),
        billing_address: this.hasAddress ? `${getValueFromGivenObjectByKey(default_address, 'street', '—')}, ${getValueFromGivenObjectByKey(default_address, 'line2', '—')}, ${getValueFromGivenObjectByKey(default_address, 'city', '—')}, ${getValueFromGivenObjectByKey(default_address, 'state.code', '—')} ${getValueFromGivenObjectByKey(default_address, 'zip', '—')}, ${getValueFromGivenObjectByKey(default_address, 'country.three_letter', '—')}` : 'Customer Missing Address',
        internal_account_notes: getValueFromGivenObjectByKey(customer_detail, 'comments', ''),
        tax_rule: getValueFromGivenObjectByKey(customer_detail, 'tax_rule', null),
      })
    },
  },
  setup() {
    const {
      MODULE_NAME,
      MODULE_NAME_ORDERS,
      customerInformation,
      ORDER_INFORMATION_TITLE,
      suggestionTableColumnsSupplier,
    } = config()

    const MODULE_NAME_CUSTOMER = 'customers'

    return {
      getProps,
      MODULE_NAME,
      MODULE_NAME_ORDERS,
      MODULE_NAME_CUSTOMER,
      customerInformation,
      ORDER_INFORMATION_TITLE,
      getValueFromGivenObjectByKey,
      suggestionTableColumnsSupplier,
    }
  },
}

</script>
<style lang="scss">
.billing_address-border-danger  {
   & textarea,
   & label,{
      border-color: #6f1214;
      color: #6f1214;
   }

  & label {
    font-weight: bold;
  }
}
</style>
